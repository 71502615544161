import * as React from "react"
import { Link } from "gatsby"
import { useRef, useEffect, useState } from "react"

import PitchLayout from "../components/pitchLayout"
import CheckDevice from '../components/checkMobile'
import Seo from "../components/seo"
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollSmoother } from "gsap/ScrollSmoother"

import CollectifLogo from "../images/svg/logo-collectif.svg"
import DownArrow from "../images/svg/arrow-down.svg"

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const CollectifPage = ({ location }) => {

  const [isMobile, setIsMobile] = useState(CheckDevice);

  const smoother = useRef(null);
  const main = useRef();
  const gsapMedia = gsap.matchMedia();

  useEffect(() => {
    // gsapMedia.add("(min-width: 1023px)", () => {
      smoother.current = ScrollSmoother.create({
        smooth: 2, // seconds it takes to "catch up" to native scroll position
        effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
        smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        normalizeScroll: true,
        preventDefault: true,
      });
    // });
  });

  // ScrollSmoother.create({
  //   smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
  //   effects: true, // looks for data-speed and data-lag attributes on elements
  //   smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
  // });

  return (
    <>
      <PitchLayout location={location} pageCategory="agency">
        <Seo title="MullenLowe U.S. | Rémy - The Collectíf" />

        <div className="one-off-landing pitch-page pitch-page-collectif">
          <div className="inner-pitch">
            <div className="pitch-intro" dataSpeed="0.95">
              <p data-speed="1.5">It was a pleasure meeting you today. Here you will find a <a href={"https://cdn.mullenloweus.agency/files/R%C3%A9myMartin_SocialRFI_MeetingDeck_01.31.2024.pdf"} target="_blank">link to our presentation</a> and more information
      about <Link to="/agency/about">our agency</Link>.</p>
              <span className="arrow-skip">
                <DownArrow className="pitch-arrow" /> 
              </span>
            </div>

            <div className="recipe-name-card mobile-hide">
              Collectíf Fashion Recipe
              <span>A refreshing mix of France and New England.<br/>Made in New York.</span>
            </div>

            <div className="pitch-recipe" id="recipe">
              <div className="recipe-column" data-speed="0.8">
                <h3 className="recipe-subheader">Ingredients</h3>
                <div className="recipe-details">
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">2 oz.</span>
                    <span className="ingdt-name">Rémy Martin VSOP</span>
                  </div>
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">1</span>
                    <span className="ingdt-name">sugar cube</span>
                  </div>
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">2</span>
                    <span className="ingdt-name">orange chunks for muddling</span>
                  </div>
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">2</span>
                    <span className="ingdt-name">dashes cranberry bitters</span>
                  </div>
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">2 oz.</span>
                    <span className="ingdt-name">cranberry juice</span>
                  </div>
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">1</span>
                    <span className="ingdt-name">lime sparkling water</span>
                  </div>
                </div>
                <h3 className="recipe-subheader">Garnishes</h3>
                <div className="recipe-details">
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">1</span>
                    <span className="ingdt-name">fresh cranberry</span>
                  </div>
                  <div className="recipe-ingredients">
                    <span className="ingdt-amt">1</span>
                    <span className="ingdt-name">orange rind</span>
                  </div>
                </div>
              </div>
              <div className="recipe-column recipe-video" dataSpeed="1">
                <CollectifLogo className="collectif-logo desktop-hide in-recipe" />

                <video
                  poster=""
                  title=""
                  autoPlay
                  loop
                  playsInline
                  muted
                  preload="none"
                >
                  <source src="https://cdn.mullenloweus.agency/videos/RemyMartin_Cocktail_v2b.mp4" />
                </video>

                <div className="recipe-name-card desktop-hide in-recipe" data-speed="0.95">
                  Collectíf Fashion Recipe
                  <span>A refreshing mix of France and New England.<br/>Made in New York.</span>
                </div>
                
              </div>
              <div className="recipe-column" data-speed="0.8">
              <h3 className="recipe-subheader">Directions</h3>
              <div className="recipe-details">
                <p>Combine sugar cube, orange chunks, and cranberry juice. Add to a glass. Muddle ingredients. Add a splash of cranberry bitters. Pour 2 oz. Rémy Martin VSOP over ice. Add seltzer and stir. Garnish with fresh cranberries and orange rind.</p>
              </div>
                
              </div>
            </div>


            <CollectifLogo className="collectif-logo mobile-hide" data-speed="0.95" />

            <div className="offices-contact-list">
              <div>
                <p>New York</p>
                <p>
                  111 W 33rd Street<br/>
                  New York, NY 10120<br/>
                  <a href="mailto:newyork@mullenlowe.com">newyork@mullenlowe.com</a><br/>
                  +1 646 870 4500
                </p>
              </div>
              <div>
                <p>Boston</p>
                <p>
                  8th Floor, 2 Drydock Avenue<br/>
                  Boston, MA 02210<br/>
                  <a href="mailto:boston@mullenlowe.com">boston@mullenlowe.com</a><br/>
                  +1 617 226 9000
                </p>
              </div>
              <div>
                <p>Los Angeles</p>
                <p>
                  2121 Park Place, Suite 150<br/>
                  El Segundo, CA 90245<br/>
                  <a href="mailto:losangeles@mullenlowe.com">losangeles@mullenlowe.com</a><br/>
                  +1 424 738 6500
                </p>
              </div>
            </div>
          </div>
        </div>
      </PitchLayout>
    </>
  )
}

export default CollectifPage
