import * as React from "react"
import { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import "../styles/layout.scss"
import Footer from "./footer"
import Header from "./header"
import useFooterContent from "../hooks/useFooterContent"
import useHeaderContent from "../hooks/useHeaderContent"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollSmoother } from "gsap/ScrollSmoother"

gsap.registerPlugin(ScrollSmoother, ScrollTrigger)

const PitchLayout = ({ children, scheme, pageCategory, location, octoShow }) => {
  const smoother = useRef(null)
  const footerContent = useFooterContent()
  const headerContent = useHeaderContent()
  const isHomePage = location.pathname === "/"
  const isSmoothScrollPage =
    location.pathname === "/work/all-work/" || isHomePage

  useEffect(() => {
    // only create the smoothscroll after the octo has gone to avoid issues with plugin setting body height
    if (isSmoothScrollPage && !octoShow) {
      smoother.current = ScrollSmoother.create({
        effects: true,
        smoothTouch: 2,
      })
    }

    return () => {
      if (isSmoothScrollPage && !octoShow) {
        smoother.current.kill()
        setTimeout(() => (document.body.style.height = ""), 100)
        // setTimeout(() => document.body.style.overscrollBehavior= '', 100);
        // setTimeout(() => document.body.style.scrollBehavior= '', 100);
      }
    }
  }, [isSmoothScrollPage])

  return (
    <>
      <Header
        octoShow={octoShow}
        location={location}
        isHomePage={isHomePage}
        headerContent={headerContent}
        pageCategory={pageCategory}
        pageType="pitch"
      />
      <main id="smooth-wrapper" className={scheme}>
        <div id="smooth-content">
          {children}
          <Footer footerContent={footerContent} isHomePage={isHomePage} />
        </div>
      </main>
    </>
  )
}

PitchLayout.propTypes = {
  scheme: PropTypes.string,
}

PitchLayout.defaultProps = {
  scheme: "dark",
}

PitchLayout.propTypes = {
  children: PropTypes.node.isRequired,
  scheme: PropTypes.string,
  pageCategory: PropTypes.string,
  location: PropTypes.object,
  octoShow: PropTypes.bool,
}

export default PitchLayout
